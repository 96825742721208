// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-cursor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: darkgray;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 10000;
}

body {
  cursor: none;
}
a,
button,
input,
[role="button"] {
  cursor: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CustomCursor.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,0BAA0B;EAC1B,eAAe;EACf,gCAAgC;EAChC,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;AACA;;;;EAIE,uBAAuB;AACzB","sourcesContent":[".custom-cursor {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  background-color: darkgray;\n  position: fixed;\n  transform: translate(-50%, -50%);\n  pointer-events: none;\n  z-index: 10000;\n}\n\nbody {\n  cursor: none;\n}\na,\nbutton,\ninput,\n[role=\"button\"] {\n  cursor: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
