// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  padding-top: 50px;
  padding-bottom: 140px;
}

.header-menu {
  display: flex;
  justify-content: end;
  padding: 50px;
  padding-bottom: 5px;
  padding-right: 150px;
  font-size: 12px;
}

.header-left {
  font-size: 24px;
  font-weight: bold;
}

.header-right a {
  margin: 0 10px;
  text-decoration: none;
  font-size: 180px;
  font-weight: bold;
  color: white;
}

.header-menu a div {
  display: inline-block;
  padding: 20px;
  font-weight: 600;
  text-decoration: none;
  color: gray;
}

.header-menu a div:hover {
  rotate: -15deg;
  transition: rotate 0.3s ease;
}

.header-item {
  display: inline-block;
  padding: 20px;
  font-weight: 600;
  text-decoration: none;
  color: gray;
  transition: transform 2s ease;
}

@media (max-width: 800px) {
  .header {
    padding-bottom: 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,gBAAgB;EAChB,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,4BAA4B;AAC9B;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,gBAAgB;EAChB,qBAAqB;EACrB,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF","sourcesContent":[".header {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 10px 20px;\n  padding-top: 50px;\n  padding-bottom: 140px;\n}\n\n.header-menu {\n  display: flex;\n  justify-content: end;\n  padding: 50px;\n  padding-bottom: 5px;\n  padding-right: 150px;\n  font-size: 12px;\n}\n\n.header-left {\n  font-size: 24px;\n  font-weight: bold;\n}\n\n.header-right a {\n  margin: 0 10px;\n  text-decoration: none;\n  font-size: 180px;\n  font-weight: bold;\n  color: white;\n}\n\n.header-menu a div {\n  display: inline-block;\n  padding: 20px;\n  font-weight: 600;\n  text-decoration: none;\n  color: gray;\n}\n\n.header-menu a div:hover {\n  rotate: -15deg;\n  transition: rotate 0.3s ease;\n}\n\n.header-item {\n  display: inline-block;\n  padding: 20px;\n  font-weight: 600;\n  text-decoration: none;\n  color: gray;\n  transition: transform 2s ease;\n}\n\n@media (max-width: 800px) {\n  .header {\n    padding-bottom: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
