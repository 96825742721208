// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pav-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0px 50px 50px 50px;
  color: black;
}

.project-subtext {
  font-size: 20px;
  margin: 30px 50px;
}

.main-image {
  width: 100%;
  height: auto;
}

.pav-intro {
  font-size: 14px;
  margin: 15px 50px;
}

.cabin-controls-block {
  display: flex;
  margin: 50px 30px 75px 30px;
  justify-content: space-between;
  align-items: center;
}

.cabin-controls-block img {
  width: 28%;
}

.navigation-block {
  margin: 50px;
  display: flex;
}

.navigation-text-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15%;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -15px;
}

.grid-item {
  flex-basis: calc(50% - 30px);
  height: 600px;
  margin: 15px;
  overflow: hidden;
}

.grid-item img,
.grid-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .grid-item {
    flex-basis: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Pav.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,4BAA4B;EAC5B,aAAa;EACb,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".pav-title {\n  font-size: 18px;\n  font-weight: 600;\n  margin: 0px 50px 50px 50px;\n  color: black;\n}\n\n.project-subtext {\n  font-size: 20px;\n  margin: 30px 50px;\n}\n\n.main-image {\n  width: 100%;\n  height: auto;\n}\n\n.pav-intro {\n  font-size: 14px;\n  margin: 15px 50px;\n}\n\n.cabin-controls-block {\n  display: flex;\n  margin: 50px 30px 75px 30px;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.cabin-controls-block img {\n  width: 28%;\n}\n\n.navigation-block {\n  margin: 50px;\n  display: flex;\n}\n\n.navigation-text-block {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-left: 15%;\n}\n\n.grid-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin: 0 -15px;\n}\n\n.grid-item {\n  flex-basis: calc(50% - 30px);\n  height: 600px;\n  margin: 15px;\n  overflow: hidden;\n}\n\n.grid-item img,\n.grid-item video {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n@media (max-width: 600px) {\n  .grid-item {\n    flex-basis: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
