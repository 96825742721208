// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-size: 18px;
  font-weight: 600;
  margin: 0px 50px 50px 50px;
  color: black;
}

.blooper {
  font-size: 16px;
  font-weight: 600;
  margin: 50px;
  color: black;
}

.project-subtext {
  font-size: 20px;
  margin: 30px 50px;
}

.main-image {
  width: 100%;
  height: auto;
}

.intro {
  font-size: 14px;
  margin: 15px 50px;
}

.text-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-block img {
  width: 60%;
  border-radius: 10px;
  margin-left: 30px;
}

.grid-container-origin {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -15px;
}

.grid-item-origin {
  flex-basis: calc(50% - 30px);
  height: 450px;
  margin: 15px;
  overflow: hidden;
}

.grid-item-origin img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.grid-item video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 600px) {
  .grid-item-origin {
    flex-basis: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/OriginDJ.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,4BAA4B;EAC5B,aAAa;EACb,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".title {\n  font-size: 18px;\n  font-weight: 600;\n  margin: 0px 50px 50px 50px;\n  color: black;\n}\n\n.blooper {\n  font-size: 16px;\n  font-weight: 600;\n  margin: 50px;\n  color: black;\n}\n\n.project-subtext {\n  font-size: 20px;\n  margin: 30px 50px;\n}\n\n.main-image {\n  width: 100%;\n  height: auto;\n}\n\n.intro {\n  font-size: 14px;\n  margin: 15px 50px;\n}\n\n.text-block {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.text-block img {\n  width: 60%;\n  border-radius: 10px;\n  margin-left: 30px;\n}\n\n.grid-container-origin {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin: 0 -15px;\n}\n\n.grid-item-origin {\n  flex-basis: calc(50% - 30px);\n  height: 450px;\n  margin: 15px;\n  overflow: hidden;\n}\n\n.grid-item-origin img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n.grid-item video {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n\n@media (max-width: 600px) {\n  .grid-item-origin {\n    flex-basis: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
