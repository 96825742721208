// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noa-logo {
  display: flex;
  gap: 15px;
  font-weight: 1000;
  font-size: 40px; 
  color: #f4f1ef;
}

.letter-box {
  position: relative;
  height: 70px; 
  width: 70px; 
  display: flex;
  align-items: center; 
  justify-content: center; 
}

.letter-box:nth-child(1) {
  height: 70px;
  width: 55px; 
}
.letter-box:nth-child(2) {
  height: 50px; 
  width: 40px; 
  margin-top: 30px;
}
.letter-box:nth-child(3) {
  height: 55px; 
  width: 55px; 
  font-size: 35px;
}

.letter-box:nth-child(1) span {
  position: absolute;
  left: -2px; 
  top: 50%;
  font-size: 45px;
  transform: translateY(-50%);
}

.letter-box:nth-child(2) span {
  position: absolute;
  left: 5px; 
  top: 5%; 
}

.letter-box:nth-child(3) span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/styles/NoaLogo.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,iBAAiB;EACjB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;AACT;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,gCAAgC;AAClC","sourcesContent":[".noa-logo {\n  display: flex;\n  gap: 15px;\n  font-weight: 1000;\n  font-size: 40px; \n  color: #f4f1ef;\n}\n\n.letter-box {\n  position: relative;\n  height: 70px; \n  width: 70px; \n  display: flex;\n  align-items: center; \n  justify-content: center; \n}\n\n.letter-box:nth-child(1) {\n  height: 70px;\n  width: 55px; \n}\n.letter-box:nth-child(2) {\n  height: 50px; \n  width: 40px; \n  margin-top: 30px;\n}\n.letter-box:nth-child(3) {\n  height: 55px; \n  width: 55px; \n  font-size: 35px;\n}\n\n.letter-box:nth-child(1) span {\n  position: absolute;\n  left: -2px; \n  top: 50%;\n  font-size: 45px;\n  transform: translateY(-50%);\n}\n\n.letter-box:nth-child(2) span {\n  position: absolute;\n  left: 5px; \n  top: 5%; \n}\n\n.letter-box:nth-child(3) span {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
