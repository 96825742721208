// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 25px;
  color: black;
}

.project-description-block {
  display: flex;
  justify-content: center;
  padding: 0px 70px;
}
.project-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 140px;
  align-items: flex-start;
}

.project-row {
  padding: 0px 70px;
  max-width: 55%;
}

.project-description {
  font-size: 14px;
  font-weight: 400;
}

.project-description-inner-block {
  display: flex;
  flex-direction: column;
}

video {
  cursor: none !important;
}

@media (max-width: 1000px) {
  .project-title {
    font-size: 22px;
    margin: 15px 0px;
  }

  .project-row {
    margin: 0px 40px 15px 40px;
    max-width: 85%;
  }

  .project-description {
    font-size: 14px;
    margin-bottom: 25px;
  }

  .project-block {
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Design.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,oBAAoB;EACpB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,0BAA0B;IAC1B,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;EACrB;AACF","sourcesContent":[".project-title {\n  font-size: 28px;\n  font-weight: 600;\n  margin-bottom: 25px;\n  color: black;\n}\n\n.project-description-block {\n  display: flex;\n  justify-content: center;\n  padding: 0px 70px;\n}\n.project-block {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 140px;\n  align-items: flex-start;\n}\n\n.project-row {\n  padding: 0px 70px;\n  max-width: 55%;\n}\n\n.project-description {\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.project-description-inner-block {\n  display: flex;\n  flex-direction: column;\n}\n\nvideo {\n  cursor: none !important;\n}\n\n@media (max-width: 1000px) {\n  .project-title {\n    font-size: 22px;\n    margin: 15px 0px;\n  }\n\n  .project-row {\n    margin: 0px 40px 15px 40px;\n    max-width: 85%;\n  }\n\n  .project-description {\n    font-size: 14px;\n    margin-bottom: 25px;\n  }\n\n  .project-block {\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
