// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-block {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 50px;
  margin-top: 0px;
}

.profile-block img {
  border-radius: 200px;
  width: 25%;
}

.profile-block-div {
  width: 50%;
}

@media (max-width: 800px) {
  .profile-block {
    flex-direction: column;
    align-items: center;
  }

  .profile-block-div {
    width: auto;
  }

  .profile-block img {
    width: 50%;
    margin-bottom: 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/About.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,UAAU;IACV,mBAAmB;EACrB;AACF","sourcesContent":[".profile-block {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  margin: 50px;\n  margin-top: 0px;\n}\n\n.profile-block img {\n  border-radius: 200px;\n  width: 25%;\n}\n\n.profile-block-div {\n  width: 50%;\n}\n\n@media (max-width: 800px) {\n  .profile-block {\n    flex-direction: column;\n    align-items: center;\n  }\n\n  .profile-block-div {\n    width: auto;\n  }\n\n  .profile-block img {\n    width: 50%;\n    margin-bottom: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
