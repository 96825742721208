// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.artwork-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.artwork-row img {
  margin: 10px;
  width: 30%;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .artwork-row {
    display: block;
  }

  .art-block-one {
    display: block;
    height: auto;
  }

  .art-block-two {
    display: block;
    height: auto;
  }

  .artwork-row img {
    width: 100%; 
    margin: 5px 0; 
  }

  .column img {
    margin-bottom: 5px; 
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/ArtStyles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ;;AAEA,mCAAmC;AACnC;EACE;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,YAAY;EACd;;EAEA;IACE,cAAc;IACd,YAAY;EACd;;EAEA;IACE,WAAW;IACX,aAAa;EACf;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".artwork-row {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.artwork-row img {\n  margin: 10px;\n  width: 30%;\n}\n\n/* Media query for mobile devices */\n@media (max-width: 600px) {\n  .artwork-row {\n    display: block;\n  }\n\n  .art-block-one {\n    display: block;\n    height: auto;\n  }\n\n  .art-block-two {\n    display: block;\n    height: auto;\n  }\n\n  .artwork-row img {\n    width: 100%; \n    margin: 5px 0; \n  }\n\n  .column img {\n    margin-bottom: 5px; \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
